var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"form-wrapper"},[_c('van-form',{ref:"form"},[_c('van-field',{attrs:{"name":"applyName","label":"用户名","placeholder":"请输入姓名","required":""},model:{value:(_vm.form.applyName),callback:function ($$v) {_vm.$set(_vm.form, "applyName", $$v)},expression:"form.applyName"}}),_c('van-field-select',{attrs:{"options":_vm.idCardTypes,"fieldProps":{
        label: '证件类型',
        required: true,
        placeholder: '请选择证件类型',
      }},model:{value:(_vm.form.idCardType),callback:function ($$v) {_vm.$set(_vm.form, "idCardType", $$v)},expression:"form.idCardType"}}),_c('van-field-select',{attrs:{"options":_vm.genderTypes,"fieldProps":{
        label: '性别',
        required: true,
        placeholder: '请选择性别',
      }},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}}),_c('div',{staticStyle:{"display":"flex"}},[_c('van-field',{attrs:{"name":"idCardNum","label":"证件号码","placeholder":"请输入证件号码","required":""},model:{value:(_vm.form.idCardNum),callback:function ($$v) {_vm.$set(_vm.form, "idCardNum", $$v)},expression:"form.idCardNum"}}),_c('van-button',{staticClass:"idCardNum-button",on:{"click":_vm.gccrcsbhy}},[_vm._v("查询证书")])],1),_c('van-field',{attrs:{"name":"phone","label":"手机号","placeholder":"请输入手机号","required":"","maxlength":"11"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('van-field',{attrs:{"name":"hasHighTalent","label":"高层次人才类别","placeholder":"请输入高层次人才类别","required":"","disabled":_vm.hasHighTalent},model:{value:(_vm.form.highTalentType),callback:function ($$v) {_vm.$set(_vm.form, "highTalentType", $$v)},expression:"form.highTalentType"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }