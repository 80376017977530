<template>
  <!--
  *@des: 人才绿卡申领
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>

      <van-field-select
        v-model="form.gender"
        :options="genderTypes"
        :fieldProps="{
          label: '性别',
          required: true,
          placeholder: '请选择性别',
        }"
      ></van-field-select>
      <div style="display: flex">
        <van-field
          v-model="form.idCardNum"
          name="idCardNum"
          label="证件号码"
          placeholder="请输入证件号码"
          required
        />
        <van-button class="idCardNum-button" @click="gccrcsbhy"
          >查询证书</van-button
        >
      </div>

      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field
        v-model="form.highTalentType"
        name="hasHighTalent"
        label="高层次人才类别"
        placeholder="请输入高层次人才类别"
        required
        :disabled="hasHighTalent"
      />
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import { gccrcsbhy } from "@/api/policy/apply";

export default {
  name: "talent-green-apply",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        highTalentType: "", //高层次人才类别
        identifyDate: "", //人才认证日期
        gender: "",
      },
      formRules: {
        applyName: [
          {
            required: true,
            message: "请输入用户名",
          },
        ],
        idCardType: [
          {
            required: true,
            message: "请选择证件类型",
          },
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
          },
        ],
        idCardNum: [
          {
            required: true,
            message: "请输入证件号码",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式",
          },
        ],
        highTalentType: [
          {
            required: true,
            message: "请查询证书或输入高层次人才类别",
          },
        ],
      },
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
        {
          value: "护照",
          label: "护照",
        },
      ],
      genderTypes: [
        {
          value: "男",
          label: "男",
        },
        {
          value: "女",
          label: "女",
        },
      ],
      hasHighTalent: true,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.global.userInfo;
    },
  },
  created() {
    // this.initFormValidator();
  },
  methods: {
    /*
     *@des: 获取高层次人才信息
     */
    gccrcsbhy() {
      if (!this.form.applyName || !this.form.idCardNum || !this.form.idCardType) {
        this.$toast("用户名与证件号码不能为空");
        return;
      }
      const idCardRule =
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/;
      if (
        "居民身份证" == this.form.idCardType &&
        !idCardRule.test(this.form.idCardNum)
      ) {
        this.$toast("证件号格式不正确");
        return;
      }
      gccrcsbhy({
        perCard: this.form.idCardNum,
        perName: this.form.applyName,
        type: "居民身份证" == this.form.idCardType ? "1" : "0",
      })
        .then((res) => {
          let { success, data } = res;
          if (success) {
            this.form.highTalentType = data[0].endCertificateName;
            this.form.identifyDate = data[0].endDate;
          } else {
            this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
            this.hasHighTalent = false;
          }
        })
        .catch((e) => {
          this.$toast("暂无高层次人才认证,请手动输入高层次人才类别");
          this.hasHighTalent = false;
        });
    },
    /*
     *@des: 初始化校验
     */
    initFormValidator() {
      const idCardRule = [
        {
          required: true,
          message: "请输入证件号",
          trigger: "blur",
        },
        {
          pattern:
            /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: "证件号格式不正确",
          trigger: "blur",
        },
      ];
      const passPortRule = {
        required: true,
        message: "请输入证件号",
        trigger: "blur",
      };
      if ("居民身份证" == this.form.idCardType) {
        this.formRules.idCardNum = idCardRule;
      } else {
        this.formRules.idCardNum = passPortRule;
      }
      this.formValidator = new Schema(this.formRules);
    },
    /*
     *@des: 校验表单
     */
    validate() {
      this.initFormValidator()
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            resolve(this.form);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
.idCardNum-button {
  width: 2rem;
  padding: 0 0.15rem;
}
</style>